import React from 'react';

import './Header.css';

function Header() {

    return (
        <div id="header">
            <span>Cyril Piejougeac</span>
            <span>Portfolio</span>
        </div>
    );
}

export default Header;
