import React from 'react';

import './Grid.css';

function Grid(props) {

    return (
        <div id="grid">
            {props.children}
        </div>
    );
}

export default Grid;
