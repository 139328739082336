import React, { useEffect, useState } from 'react';
import { useTransition, animated, config } from 'react-spring';

import Grid from '../Grid/Grid';
import Header from '../Header/Header';
import Tile from '../Tile/Tile';
import Details from '../Details/Details';

import './App.css';

import audiumImg from '../../assets/img/audium.jpg';
import champiaImg from '../../assets/img/champia.jpg';
import kulaImg from '../../assets/img/kula.png';
import khetImg from '../../assets/img/khet.jpg';
import supweatherImg from '../../assets/img/supweather.jpg';
import danslemealImg from '../../assets/img/danslemeal.jpg';
import instabusImg from '../../assets/img/instabus.jpg';

import khetVideo from '../../assets/videos/khet.mp4';
import khetVideoThumb from '../../assets/img/khet_thumb.png';
import instabusVideo from '../../assets/videos/instabus.mp4';
import instabusVideoThumb from '../../assets/img/instabus_thumb.png';

const AnimatedTile = animated(Tile);
const AnimatedDetails = animated(Details);

const PROJECTS_DATA = [
    {
        project: "champia",
        img: champiaImg, 
        summary: { 
            title: "Champ'IA", 
            paragraph: "AI based Web application of mushroom recognition" 
        },
        details: {
            paragraphs: [
                "Champ'IA is a mushroom recognition Web application. This app allows you to recognize 6 different species of fungus: Amanita Bisporigera, Amanita Muscaria, Boletus Edulis, Cantharellus and Omphalotus Olearius. The ultimate goal is to find out whether the fungus is edible or not.",
                "The creation of the detection model was done in Python with the Keras and Tensorflow libraries and the web application was developed with Flask.",
                "It is strongly recommended to test Champ'IA on smartphone in order to take advantage of the camera.",
                "WARNING! This is a technical demonstration and should not be used in real conditions. The consumption of unknown mushrooms can be dangerous for health."
            ],
            link: "https://champia.cyrilpiejougeac.dev",
            gitlab: "https://gitlab.com/Luxeon/champ-ia"
        },
        delay: 100
    },
    { 
        project: "khet", 
        img: khetImg, 
        summary: { 
            title: "Khet 2.0", 
            paragraph: "Remake of the board game \"Khet: The Laser Game\" in Python" 
        },
        details: {
            paragraphs: [
                "Khet 2.0 is a virtual remake of the board game \"Khet: The Laser Game\". This is a project carried out during my 1st year of computer science study.",
                "The game was made in Python with the Pygame library."
            ],
            video: khetVideo,
            thumbnail: khetVideoThumb,
            gitlab: "https://gitlab.com/Luxeon/khet-2.0"
        },
        delay: 600 
    },
    { 
        project: "instabus", 
        img: instabusImg, 
        summary: { 
            title: "InstaBus", 
            paragraph: "Android application of photo sharing" 
        },
        details: {
            paragraphs: [
                "Instabus is an Android application that allows users to take photos at Barcelona bus stations. This is a project carried out as part of my studies for an Android development course.",
                "The application was developed in Kotlin and uses SQLite for data storage."
            ],
            video: instabusVideo,
            thumbnail: instabusVideoThumb,
            gitlab: "https://gitlab.com/Luxeon/instabus"
        },
        delay: 200 
    },
    { 
        project: "audium", 
        img: audiumImg, 
        summary: { 
            title: "Audium", 
            paragraph: "Web Music Player" 
        },
        details: {
            paragraphs: [
                "Audium is a Web music player developped in ASP.NET.", 
                "You can create an account and after that, you have the possibility to create, modify and delete playlists and add music files to them.", 
                "And you can of course listen to your music as in any other music player, with a nice spectrum animation."
            ],
            link: "https://audium.cyrilpiejougeac.dev",
            gitlab: "https://gitlab.com/Luxeon/audium"
        },
        delay: 400 
    },
    { 
        project: "kula", 
        img: kulaImg, 
        summary: { 
            title: "Kula World 2", 
            paragraph: "Remake of the PS1 game \"Kula World\" with Unity" 
        },
        details: {
            paragraphs: [
                "Kula World 2 is a remake of the puzzle video game \"Kula World\" released on PlayStation 1 in 1998. The goal is to solve different 3-dimensional mazes by collecting the different keys within a given time.",
                "Kula World 2 was made with Unity and the C# language. In this version, it is possible to play the game locally with the same rules as the original game but also to create your own levels.",
                "You can use the executable files in the Gitlab repository to test it or you can try it directly in your web browser with the link below."
            ],
            link: "https://kulaworld.cyrilpiejougeac.dev",
            gitlab: "https://gitlab.com/Luxeon/kula-world-2"
        },
        delay: 700 
    },
    { 
        project: "supweather", 
        img: supweatherImg, 
        summary: { 
            title: "SupWeather", 
            paragraph: "Weather monitoring Website" 
        },
        details: {
            paragraphs: [
                "SupWeather is a website that allows you to follow the weather of some cities you choose.",
                "After creating an account, you can search for cities and add them to your dashboard. It is possible to consult the weather forecast for the moment but also for the next 5 days.",
                "SupWeather was developed on the MERN stack (MongoDB, ExpressJS, React and NodeJS)."
            ],
            link: "https://supweather.cyrilpiejougeac.dev",
            gitlab: "https://gitlab.com/Luxeon/supweather"
        },
        delay: 300 
    },
    { 
        project: "danslemeal", 
        img: danslemealImg, 
        summary: { 
            title: "Dans le Meal", 
            paragraph: "Recipe search Website" 
        },
        details: {
            paragraphs: [
                "Dans le Meal is a cooking recipe search website.", 
                "This website base the search on your preferences in terms of diet or type of cuisine to offer you the recipes you want. You can also save recipes as favorites",
                "Dans le Meal was made with React."
            ],
            link: "https://danslemeal.cyrilpiejougeac.dev",
            gitlab: "https://gitlab.com/Luxeon/dans-le-meal"
        },
        delay: 500 
    }
]

function App() {

    const [selectedProject, setSelectedProject] = useState(null);
    const [gridVisible, setGridVisible] = useState(true);
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [mobileHoveredProject, setMobileHoveredProject] = useState(null);

    const [items, setItems] = useState(PROJECTS_DATA);

    const tileTransitions = useTransition(items, {
        from: { transform: 'scale(0.6)', opacity: 0 },
        enter: item => (next) => (
            next({ transform: 'scale(1)', opacity: 1, delay: item.delay })
        ),
        leave: { transform: 'scale(0.6)', opacity: 0 },
        delay: 100,
        config: config.stiff
    })

    const detailsTransition = useTransition(detailsVisible, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    })

    useEffect(() => {
        setItems(selectedProject ? [] : PROJECTS_DATA);
    }, [selectedProject])

    useEffect(() => {
        if (!gridVisible) {
            setDetailsVisible(true);
        }
        else {
            setItems(PROJECTS_DATA);
            setSelectedProject(null);
        }
    }, [gridVisible])

    return (
        <div id="app">
            <Header />

            {gridVisible &&
                <Grid>
                    {
                        tileTransitions((style, item) => item ? <AnimatedTile style={style} project={item.project} img={item.img} description={item.summary} onClick={() => setSelectedProject(item)} onUnmount={() => setGridVisible(false)} mobileHovered={item.project === mobileHoveredProject} setMobileHovered={setMobileHoveredProject} /> : '')
                    }
                </Grid>
            }

            {
                detailsTransition((style, item) => item ? <AnimatedDetails style={style} onClose={() => setDetailsVisible(false)} onUnmount={() => setGridVisible(true)} project={selectedProject} /> : '')
            }
        </div>
    );
}

export default App;
