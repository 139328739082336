import React, { useEffect } from 'react';

import './Details.css';

function Details({ style, onClose, onUnmount, project }) {

    useEffect(() => {
        return function cleanup() {
            onUnmount();
        }
    }, [])

    return (
        <div id="details" style={style}>
            <button className="back-button" onClick={onClose}><i className="lni lni-arrow-left"></i>Back</button>

            <h1>{project.summary.title}</h1>

            <div className="content">
                <div className="text">
                    <div>
                        {
                            project.details.paragraphs.map((p, i) => <p key={i}>{p}</p>)
                        }
                    </div>

                    <div className="links">
                        {
                            project.details.link ?
                                <a href={project.details.link} target="_blank" rel="noreferrer"><i className="lni lni-pointer-right"></i> Try it yourself <i className="lni lni-pointer-left"></i></a>
                                :
                                <p>Check it out in video</p>
                        }
                        <a href={project.details.gitlab} target="_blank" rel="noreferrer">Gitlab Link</a>
                    </div>

                </div>
                <div className="media">
                    {
                        project.details.video ?
                            <video controls poster={project.details.thumbnail}>
                                <source src={project.details.video} type="video/mp4" />
                            </video>
                            :
                            <img src={project.img} alt="" />
                    }
                </div>
            </div>
        </div>
    );
}

export default Details;
