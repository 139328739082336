import React, { useEffect, useState } from 'react';
import { useTransition, animated } from 'react-spring';
import { isMobile } from 'react-device-detect';

import './Tile.css';

function Tile({ img, project, style, description, onClick, onUnmount, mobileHovered, setMobileHovered }) {

    const [hovered, setHovered] = useState(false);

    const transition = useTransition(hovered, {
        from: { opacity: 0 },
        enter: { opacity: .8 },
        leave: { opacity: 0}
    })

    useEffect(() => {
        return function cleanup() {
            onUnmount();
        }
    }, [])

    useEffect(() => {
        setHovered(mobileHovered);
    }, [mobileHovered])

    const mobileOnClick = () => {
        if (isMobile && !hovered) {
            setMobileHovered(project);
        }
        else if (isMobile && hovered) {
            onClick();
            setMobileHovered(null);
        }
        else if (!isMobile) {
            onClick();
        }
    }

    return (
        <div className={`tile ${project}`} style={style} onMouseEnter={() => {if (!isMobile) setHovered(true)}} onMouseLeave={() => {if (!isMobile) setHovered(false)}} onClick={mobileOnClick}>
            <img src={img} alt="" />
            {
                transition((style, item) => item ?
                    <animated.div style={style} className="description">
                        <h1>{description.title}</h1>
                        <p>{description.paragraph}</p>
                        {
                            isMobile && <span>See More <i className="lni lni-arrow-right"></i></span>
                        }
                    </animated.div> : '')
            }
        </div>
    );
}

export default Tile;
